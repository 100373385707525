import * as Redux from 'react-redux';

import {
    combineReducers,
    configureStore,
    PayloadAction as TivoPayloadAction,
} from '@reduxjs/toolkit';

import RootReducer from '../../store/root.reducers';

// Configuring app store
// __UNCOMMENT_DEBUG_CODE__
const isDevToolsAvailable: boolean = false;

const KaveriAppStore = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            actionCreatorCheck: false,
            immutableCheck: false,
            serializableCheck: false,
        }),
    reducer: combineReducers(RootReducer),
    devTools: isDevToolsAvailable,
});

// Define types here
type RootState = ReturnType<typeof KaveriAppStore.getState>;
type AppDispatch = typeof KaveriAppStore.dispatch;
type TGetState = () => RootState;

// Define functions here
const TivoProvider = Redux.Provider;

// Define hooks here
const useTivoDispatch = Redux.useDispatch.withTypes<AppDispatch>();
const useTivoSelector = Redux.useSelector.withTypes<RootState>();

// Export types from here
export type { AppDispatch, RootState, TGetState, TivoPayloadAction };

// Export other than types from here
export { KaveriAppStore, TivoProvider, useTivoDispatch, useTivoSelector, Redux };
